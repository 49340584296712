<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-12, .cls-13, .cls-2, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#glass-pattern);
      }

      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7 {
        fill: url(#inox-gradient-4);
      }

      .cls-8 {
        fill: url(#inox-gradient-5);
      }

      .cls-9 {
        fill: url(#inox-gradient-6);
      }

      .cls-10 {
        fill: url(#inox-gradient-7);
      }

      .cls-11 {
        fill: url(#inox-gradient-8);
      }

      .cls-12 {
        fill: url(#inox-gradient-9);
      }

      .cls-13 {
        fill: url(#handle-gradient);
      }
      .handle { fill: url(#handle-gradient); }
    </svg:style>
    <linearGradient id="glass-gradient" x1="35.18" y1="279.66" x2="35.18" y2="22.26" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="inox-gradient"
      :x1="doorLeftWidth1 + 94.71"
      :y1="doorTopHeight1 + 65.1"
      :x2="doorLeftWidth1 + 94.71"
      :y2="doorTopHeight1 + 55.18"
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox"
      :x1="doorLeftWidth1 + 84.78"
      :y1="doorTopHeight1 + 87.81"
      :x2="doorLeftWidth1 + 84.78"
      :y2="doorTopHeight1 + 77.87"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox"
      :x1="doorLeftWidth1 + 94.71"
      :y1="doorTopHeight1 + 110.52"
      :x2="doorLeftWidth1 + 94.71"
      :y2="doorTopHeight1 + 100.58"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox"
      :x1="doorLeftWidth1 + 84.78"
      :y1="doorTopHeight1 + 133.22"
      :x2="doorLeftWidth1 + 84.78"
      :y2="doorTopHeight1 + 123.29"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox"
      :x1="doorLeftWidth1 + 94.71"
      :y1="doorTopHeight1 + 155.93"
      :x2="doorLeftWidth1 + 94.71"
      :y2="doorTopHeight1 + 145.99"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox"
      :x1="doorLeftWidth1 + 84.78"
      :y1="doorTopHeight1 + 178.63"
      :x2="doorLeftWidth1 + 84.78"
      :y2="doorTopHeight1 + 168.7"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-7" data-name="inox"
      :x1="doorLeftWidth1 + 94.71"
      :y1="doorTopHeight1 + 201.33"
      :x2="doorLeftWidth1 + 94.71"
      :y2="doorTopHeight1 + 191.41"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-8" data-name="inox"
      :x1="doorLeftWidth1 + 84.78"
      :y1="doorTopHeight1 + 224.04"
      :x2="doorLeftWidth1 + 84.78"
      :y2="doorTopHeight1 + 214.1"
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-9" data-name="inox"
      :x1="doorLeftWidth1 + 94.71"
      :y1="doorTopHeight1 + 246.75"
      :x2="doorLeftWidth1 + 94.71"
      :y2="doorTopHeight1 + 236.81"
      xlink:href="#inox-gradient"/>
    <linearGradient id="handle-gradient"
      :x1="doorLeftWidth1 + 11.34"
      :y1="doorTopHeight1 + 150.96"
      :x2="doorLeftWidth1 + 17.02"
      :y2="doorTopHeight1 + 150.96"
      xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="L21">
      <rect filter="url(#inset-shadow)" id="glass" class="cls-2" :x="`${doorLeftWidth + 22.26}`" :y="`${doorTopHeight + inoxOffset}`" width="25.83" :height="inoxHeight"/>
      <line
        v-if="showImpost"
        class="line" :x1="`${doorLeftWidth + 59.58}`" :y1="`${inoxHeight + doorTopHeight + inoxOffset}`" :x2="`${doorLeftWidth + 59.58}`" :y2="`${doorTopHeight + 22.26}`"/>
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 65.1} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 65.1} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 55.18} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 55.18} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 65.1}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 87.81} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 87.81} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 77.88} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 77.88} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 87.81}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 110.52} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 110.52} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 100.58} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 100.58} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 110.52}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 133.22} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 133.22} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 123.29} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 123.29} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 133.22}`"/>
      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-8"
                :points="`${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 155.93} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 155.93} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 145.99} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 145.99} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 155.93}`"/>
      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-9"
                :points="`${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 178.63} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 178.63} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 168.7} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 168.7} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 178.63}`"/>
      <polyline id="inox-7"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 201.33} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 201.33} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 191.41} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 191.41} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 201.33}`"/>
      <polyline id="inox-8"
                v-if="showInox"
                data-name="inox"
                class="cls-11"
                :points="`${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 224.04} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 224.04} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 214.1} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 214.1} ${doorLeftWidth1 + 79.81} ${doorTopHeight1 + 224.04}`"/>
      <polyline id="inox-9"
                v-if="showInox"
                data-name="inox"
                class="cls-12"
                :points="`${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 246.75} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 246.75} ${doorLeftWidth1 + 99.68} ${doorTopHeight1 + 236.81} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 236.81} ${doorLeftWidth1 + 89.74} ${doorTopHeight1 + 246.75}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.26
    }
  },
  computed: {
    showImpost() {
      return this.showBg;
    },
    inoxHeight() {
      return this.doorHeight - 2 * this.inoxOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 70
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 150
    },
  },
}
</script>
